import React, { useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import configs from "../../utils/configs";
import { CreateRoomButton } from "./CreateRoomButton";
import { PWAButton } from "./PWAButton";
import { useFavoriteRooms } from "./useFavoriteRooms";
import { usePublicRooms } from "./usePublicRooms";
import styles from "./HomePage.scss";
import { AuthContext } from "../auth/AuthContext";
import { createAndRedirectToNewHub } from "../../utils/phoenix-utils";
import { PageContainer } from "../layout/PageContainer";
import { Container } from "../layout/Container";
import { SignInButton } from "./SignInButton";
import { AppLogo } from "../misc/AppLogo";
import maskEmail from "../../utils/mask-email";
import { Partners } from "./Partners";
import {Socialise} from "./Socialise";
import {Features} from "./Features";
import {ImageComponent} from "./ImageComponent";
import {HeroBluemoon} from "./HeroBluemoon";
import {OwnSpaceInMetaverse} from "./OwnSpaceInMetaverse";
import {SocialRightBar} from "./SocialRightBar";

export function HomePage() {
  const auth = useContext(AuthContext);
  const intl = useIntl();

  const { results: favoriteRooms } = useFavoriteRooms();
  const { results: publicRooms } = usePublicRooms();

  const sortedFavoriteRooms = Array.from(favoriteRooms).sort((a, b) => b.member_count - a.member_count);
  const sortedPublicRooms = Array.from(publicRooms).sort((a, b) => b.member_count - a.member_count);
  const wrapInBold = chunk => <b>{chunk}</b>;
  useEffect(() => {
    const qs = new URLSearchParams(location.search);

    // Support legacy sign in urls.
    if (qs.has("sign_in")) {
      const redirectUrl = new URL("/signin", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    } else if (qs.has("auth_topic")) {
      const redirectUrl = new URL("/verify", window.location);
      redirectUrl.search = location.search;
      window.location = redirectUrl;
    }

    if (qs.has("new")) {
      createAndRedirectToNewHub(null, null, true);
    }
  }, []);

  const canCreateRooms = !configs.feature("disable_room_creation") || auth.isAdmin;
  const email = auth.email;
  return (
    <PageContainer className={styles.homePage}>
      {/*<Container>*/}
      {/*  <div className={styles.hero}>*/}
      {/*    {auth.isSignedIn ? (*/}
      {/*      <div className={styles.signInContainer}>*/}
      {/*        <span>*/}
      {/*          <FormattedMessage*/}
      {/*            id="header.signed-in-as"*/}
      {/*            defaultMessage="Signed in as {email}"*/}
      {/*            values={{ email: maskEmail(email) }}*/}
      {/*          />*/}
      {/*        </span>*/}
      {/*        <a href="#" onClick={auth.signOut} className={styles.mobileSignOut}>*/}
      {/*          <FormattedMessage id="header.sign-out" defaultMessage="Sign Out" />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    ) : (*/}
      {/*      <SignInButton mobile />*/}
      {/*    )}*/}
      {/*    <div className={styles.logoContainer}>*/}
      {/*      <AppLogo />*/}
      {/*    </div>*/}
      {/*    <div className={styles.appInfo}>*/}
      {/*      <div className={styles.appDescription}>{configs.translation("app-description")}</div>*/}
      {/*      {canCreateRooms && <CreateRoomButton />}*/}
      {/*      <PWAButton />*/}
      {/*    </div>*/}
      {/*    <div className={styles.heroImageContainer}>*/}
      {/*      <img*/}
      {/*        alt={intl.formatMessage(*/}
      {/*          {*/}
      {/*            id: "home-page.hero-image-alt",*/}
      {/*            defaultMessage: "Screenshot of {appName}"*/}
      {/*          },*/}
      {/*          { appName: configs.translation("app-name") }*/}
      {/*        )}*/}
      {/*        src={configs.image("home_background")}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</Container>*/}
      <SocialRightBar />
      <HeroBluemoon />
      <OwnSpaceInMetaverse />
      <ImageComponent />
      <Features />
      <Socialise />
      <Partners />
    </PageContainer>
  );
}
