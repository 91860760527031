import React from "react";
import PropTypes from "prop-types";

import configs from "../../utils/configs";
// import { ReactComponent as HmcLogo } from "../icons/HmcLogo.svg";
import { ReactComponent as BluemoonLogo} from "../icons/BluemoonLogo.svg";
import { ReactComponent as BluemoonWhiteLogo} from "../icons/BluemoonLogoWhite.svg";
import { isHmc } from "../../utils/isHmc";
import { useLogo } from "../styles/theme";

export function AppLogo({ className, forceConfigurableLogo, isWhite }) {
  const logo = useLogo();
  // if (isHmc() && !forceConfigurableLogo) {
  //   return isWhite ? <BluemoonWhiteLogo className="bluemoon-logo" /> : <BluemoonLogo className="bluemoon-logo" />;
  // } else {
  //   return <img className={className} alt={configs.translation("app-name")} src={logo} />;
  // }

  return isWhite ? <BluemoonWhiteLogo className="bluemoon-logo" /> : <BluemoonLogo className="bluemoon-logo" />;
}

AppLogo.propTypes = {
  className: PropTypes.string,
  forceConfigurableLogo: PropTypes.bool,
  isWhite: PropTypes.bool
};
